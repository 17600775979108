<template>
  <div class="background-white">
    <div class="top_div"></div>
    <van-sticky :offset-top="1">
      <children @change="childChange" />
    </van-sticky>
    <div class="group_balance" v-if="accountBalance.accountBalance">
      <div class="text-wrapper" v-if="state == 'ivy'">
        <router-link to="/accountBalance" style="display: contents">
          <span class="text-group">{{ $t("bill.AccountCredits") }}</span
          ><van-icon name="arrow" color="#FFFFFF" style="vertical-align: middle" />
        </router-link>
      </div>
      <div class="text-wrapper" v-else>
        <span class="text-group">{{ $t("bill.AccountCredits") }}</span>
      </div>
      <div class="balance">
        <span class="balance_num">{{ accountBalance.accountBalance }}</span>
        <span class="balance_yuan">{{ $t("bill.Yuan") }}</span>
      </div>
    </div>
    <van-row justify="space-between" v-if="state == 'ivy'">
      <div class="mealDetails flex flexCenter flex1">
        <router-link to="/Lunch" style="display: contents">
          <div class="flex flex1 van-row--align-center">
            <img class="" referrerpolicy="no-referrer" src="@/assets/lunchMenu.png" alt="" />
            <span class="mealDetailsText">{{ $t("bill.LunchDetail") }}</span>
          </div>
          <van-icon name="arrow" color="#969799" style="vertical-align: middle" />
        </router-link>
      </div>
    </van-row>
    <van-list v-if="childid" v-model="loading_list" :finished="finished" :loading-text="$t('bill.Loading') + '...'" :finished-text="$t('bill.Nomoredata')" @load="onLoad" offset="300" :error.sync="error" :error-text="$t('bill.FailedClickReload')">
      <div class="billData" v-for="(item, index) in list" :key="index" @click="changeBill(item.invoice_id, 'out')">
        <div class="flex1">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="mt-8">{{ item.date }}</div>
        </div>
        <div class="amountText">
          <div class="amount">{{ item.amount_string }} <van-icon name="arrow" /></div>
          <div class="refund" v-if="item.refund_status == 10 || item.refund_status == 20">{{ $t("bill.RefundRcorded") }}</div>
        </div>
      </div>
      <!-- <van-cell v-for="(item, index) in list" value-class="cell-value" title-class="cell-title" label-class="cell-label" center is-link :key="index" :title="item.title" :value="item.amount_string" :label="item.date" @click="changeBill(item.invoice_id, 'out')"
        ><template #default
          >{{ item.amount_string }}
          <div>有退费</div>
        </template></van-cell
      > -->
    </van-list>
    <billDetail :inoutSymbol="inoutSymbol" :billingDetails="billingDetails" :billingPayment="billingPayment" :show="show" :loadingDetail="loadingDetail" :billingRefund="billingRefund" @loadingDetail="setLoadingDetail" @show="setShow" />
  </div>
</template>

<script>
import Children from "@/components/Children.vue";
import BillDetail from "@/components/BillDetail.vue";
import { Row, Cell, ActionSheet, List, Divider, Loading, Sticky } from "vant";
import Vue from "vue";
import { getBillHistory, getBillingDetails } from "@/api/bill";
import store from "../store";

Vue.use(ActionSheet, Row, Cell, List, Divider, Loading, Sticky);
export default {
  name: "BillHistory",
  components: {
    children: Children,
    billDetail: BillDetail,
  },
  inject: ["reload"],
  data() {
    return {
      childrenList: [],
      childid: "",
      childrenStatus: {},
      show: false,
      loading: false,
      accountBalance: "",
      loading_list: false,
      finished: false,
      error: false, // 是否加载失败
      list: [], // 列表

      pages: {
        page: 1,
        count: 0,
        size: 10,
      },
      inoutSymbol: "", //+ -
      billingDetails: "", //账单详情
      billingPayment: "", //付款记录
      billingRefund: [], //退款记录
      loadingDetail: true,
      state: "",
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  created() {
    this.state = store.getters.wxState || "ds";
    console.log(this.state);
  },
  watch: {
    childid(newValue, oldValue) {
      if (oldValue && newValue) {
        this.onRefresh();
      }
    },
  },
  methods: {
    // 获取列表数据方法
    getList() {
      // 注意: 这个 informList 方法是请求后端接口获取数据的方法
      // 因为不同项目里请求方法封装的各有差异,所以在此不再展开细写
      let params = {
        childId: this.childid,
        pageNum: this.pages.page,
        pageSize: this.pages.size,
      };
      getBillHistory(params).then((res) => {
        this.accountBalance=res.data
        if (res.data.invoiceInfo.length === 0) {
          this.list = []; // 清空数组
          this.finished = true; // 停止加载
        }
        this.list.push(...res.data.invoiceInfo); // 将数据放入list中
        this.loading_list = false; // 加载状态结束
        // 如果list长度大于等于总数据条数,数据全部加载完成
        if (this.list.length >= res.data.total) {
          this.finished = true; // 结束加载状态
        }
        this.pages.count = res.data.totalPage;
        this.pages.pageNum = res.data.pageNum;
        this.loading = false;
      });
    },
    // 被 @load调用的方法
    onLoad() {
      // 若加载条到了底部
      let timer = setTimeout(() => {
        // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        this.getList(); // 调用上面方法,请求数据
        this.pages.page++; // 分页数加一
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
    // 加载失败调用方法
    onRefresh() {
      this.finished = false; // 清空列表数据
      this.loading_list = true; // 将 loading 设置为 true，表示处于加载状态
      this.pages.page = 1; // 分页数赋值为1
      this.list = []; // 清空数组
      this.onLoad(); // 重新加载数据
    },
    setShow(val) {
      this.show = val;
    },
    setLoadingDetail(val) {
      this.loadingDetail = val;
    },
    childChange(val) {
      this.childid = val.id;
    },
    changeBill(invoice_id, inout = "out") {
      let params = {
        invoice_id: invoice_id,
      };
      this.inoutSymbol = inout === "in" ? "+" : "-";
      this.show = true;
      this.loadingDetail = true;
      getBillingDetails(params).then((res) => {
        this.billingDetails = res.data.detail;
        this.billingPayment = res.data.payment;
        this.billingRefund = res.data.refund;
        this.loadingDetail = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.background-white {
  background: #fff;
}

.top_div {
  height: 1px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
}

.group_balance {
  background: url("../assets/account_balance@2x.png") 100% no-repeat;
  background-size: 100% 100%;
  align-self: center;
  margin: 16px;
  padding: 16px 20px 24px 16px;

  .text-group {
    overflow-wrap: break-word;
    color: #fff;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    line-height: 20px;
    margin-right: 4px;
  }

  .balance {
    margin-top: 18px;
    overflow-wrap: break-word;
    font-size: 0;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
  }

  .balance_num {
    overflow-wrap: break-word;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
  }

  .balance_yuan {
    margin-left: 5px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: left;
  }
}

.mealDetails {
  padding: 0 12px;
  margin: 12px 16px;
  height: 40px;
  background: #f7f8fa;
  border-radius: 8px;
}

.mealDetailsText {
  height: 21px;
  font-size: 15px;
  font-weight: 400;
  color: #323233;
  line-height: 21px;
  margin-left: 8px;
}

.flexCenter {
  align-items: center;

  .flex1 {
    margin-left: 8px;
  }
}

.cell-value {
  color: #323233;
  font-weight: 500;
  font-size: 15px;
}

.cell-title {
  color: #323233;
  font-weight: 400;
  font-size: 14px;
}

.cell-label {
  color: #969799;
  font-size: 12px;
  font-weight: 400;
}
.billData {
  display: flex;
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px solid #ebedf0;
  align-items: center;
  .flex1 {
    flex: 1;
    margin-right: 16px;
    .title {
      color: #323233;
      font-weight: 400;
      font-size: 14px;
    }
    .mt-8 {
      margin-top: 8px;
      color: #969799;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .amountText {
    position: relative;
    .amount {
      font-weight: 500;
      font-size: 15px;
      color: #323233;
      i {
        color: #666;
      }
    }
    .refund {
      color: #ed6a0c;
      font-size: 12px;
      position: absolute;
      right: 20px;
      white-space: nowrap;
      margin-top: 2px;
    }
  }
}
</style>
