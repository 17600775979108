<template>
  <div class="titleTexts">
    <div class="title_1">
      <van-row type="flex" justify="end">
        <switch-lang color="#377ED7" />
      </van-row>
      <div class="ss_title">
        {{ $t('ss_online.es_title') }}
      </div>
      <div class="log_in">
        {{ $t('ss_online.es_title_01') }}
      </div>
      <div class="question pb-10" @click="show = true"><van-icon name="question-o" /> {{ $t('ss_online.support_home')
        }}
      </div>
    </div>
    <div class="list">
      <div class="title"> {{ $t('ss_online.Resources') }}</div>
      <div>
        <div>
          <div class="itemText" :class="arts ? 'active' : ''" @click="ixlShow(arts, 'arts')">
            <div class="flex1">{{ $t('ss_online.IXL_Arts') }}</div>
            <van-icon name="arrow-down" v-if="!arts" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="arts">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a class="text" href="https://www.ixl.com">https://www.ixl.com</a>
              <span class="iconfont iconfont-copy iconCopy" @click="copyText('https://www.ixl.com')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.IXL_Arts_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.G1_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4"
                  width="100%" controls></video>
              </div>
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_en_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4"
                  width="100%" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="science ? 'active' : ''" @click="ixlShow(science, 'science')">
            <div class="flex1">{{ $t('ss_online.IXL_Science') }}</div>
            <van-icon name="arrow-down" v-if="!science" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="science">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://www.ixl.com" class="text">https://www.ixl.com</a>
              <span class="iconfont iconfont-copy iconCopy" @click="copyText('https://www.ixl.com')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.science_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.G1_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4"
                  width="100%" controls></video>
              </div>
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_en_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lpwTICxYkuiII8qbO8yOcD0qANXS.mp4"
                  width="100%" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="math ? 'active' : ''" @click="ixlShow(math, 'math')">
            <div class="flex1">{{ $t('ss_online.IXL_Math') }}</div>
            <van-icon name="arrow-down" v-if="!math" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="math">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://www.ixl.com" class="text">https://www.ixl.com</a>
              <span class="iconfont iconfont-copy iconCopy" @click="copyText('https://www.ixl.com')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.math_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.G3_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <a class="pdf_url" href="https://www.ixl.com/materials/userguides/IXLQuickStart_Child.pdf">
              <img src="@/assets/PDF.png" alt="Logo" class="url" />
              <span class="text">{{ $t('ss_online.en_Tutorial') }}</span>
            </a>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lhU9_1SwfeIe38b3IALIvmXbj5NF.mp4"
                  width="100%" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="Kids ? 'active' : ''" @click="ixlShow(Kids, 'Kids')">
            <div class="flex1">Raz-Kids</div>
            <van-icon name="arrow-down" v-if="!Kids" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="Kids">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://www.kidsa-z.com/main/Login?_ga=2.86646744.1199826719.1609813274-716427517.1609813274"
                class="text">https://www.kidsa-z.com</a>
              <span class="iconfont iconfont-copy iconCopy"
                @click="copyText('https://www.kidsa-z.com/main/Login?_ga=2.86646744.1199826719.1609813274-716427517.1609813274')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.kids_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.Gk_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lkPY4daWdAUsUzLXIdvWzEslceSR.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_en_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lkPY4daWdAUsUzLXIdvWzEslceSR.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lkPY4daWdAUsUzLXIdvWzEslceSR.mp4"
                  width="100%" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="seesaw ? 'active' : ''" @click="ixlShow(seesaw, 'seesaw')">
            <div class="flex1">Seesaw</div>
            <van-icon name="arrow-down" v-if="!seesaw" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="seesaw">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://app.seesaw.me/#/login" class="text">https://app.seesaw.me/#/login</a>
              <span class="iconfont iconfont-copy iconCopy" @click="copyText('https://app.seesaw.me/#/login')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.Seesaw_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.Gk_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a class="text" href="https://help.seesaw.me/hc/en-us/sections/4525957299597-Basic-family-setup">
                {{ $t('ss_online.en_Tutorial') }}
              </a>
              <span class="iconfont iconfont-copy iconCopy"
                @click="copyText('https://help.seesaw.me/hc/en-us/sections/4525957299597-Basic-family-setup')"></span>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="newsela ? 'active' : ''" @click="ixlShow(newsela, 'newsela')">
            <div class="flex1">Newsela</div>
            <van-icon name="arrow-down" v-if="!newsela" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="newsela">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://newsela.com" class="text">https://newsela.com</a>
              <span class="iconfont iconfont-copy iconCopy" @click="copyText('https://newsela.com')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.newsela_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.G3_5') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a class="text"
                href="https://support.newsela.com/article/create-a-parent-account/#create-a-caregiver-account">{{
                  $t('ss_online.en_Tutorial') }}</a>
              <span class="iconfont iconfont-copy iconCopy"
                @click="copyText('https://support.newsela.com/article/create-a-parent-account/#create-a-caregiver-account')"></span>
            </div>
            <div class="mt-5 font12 orange"> {{ $t('ss_online.Note') }}</div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="level_chinese ? 'active' : ''" @click="ixlShow(level_chinese, 'level_chinese')">
            <div class="flex1">{{ $t('ss_online.Level_Chinese') }}</div>
            <van-icon name="arrow-down" v-if="!level_chinese" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="level_chinese">
            <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://app.levelchinese.cn/v3/login" class="text">https://app.levelchinese.cn/v3/login</a>
              <span class="iconfont iconfont-copy iconCopy"
                @click="copyText('https://app.levelchinese.cn/v3/login')"></span>
            </div>
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.Level_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10 mb-10" round type="primary" color="#F2F3F5"
                text-color="#323233">{{
                  $t('ss_online.G1_5') }}</van-tag>
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.G6_10ss') }}</van-tag>
            </div>
            <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.en_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4"
                  width="100%" controls></video>
              </div>
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4"
                  width="100%" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="itemText" :class="Reader ? 'active' : ''" @click="ixlShow(Reader, 'Reader')">
            <div class="flex1">{{ $t('ss_online.reader') }}</div>
            <van-icon name="arrow-down" v-if="!Reader" />
            <van-icon name="arrow-up" v-else />
          </div>
          <div class="activeText" v-if="Reader">
            <!-- <div class="common">
              {{ $t('ss_online.Resource_url') }}
            </div>
            <div class="pdf_url">
              <img src="@/assets/url.png" alt="Logo" class="url" />
              <a href="https://app.levelchinese.cn/v3/login" class="text">https://app.levelchinese.cn/v3/login</a>
              <span class="iconfont iconfont-copy iconCopy"
                @click="copyText('https://app.levelchinese.cn/v3/login')"></span>
            </div> -->
            <div class="common">
              {{ $t('ss_online.What_is') }}
            </div>
            <div class="introduce">
              {{ $t('ss_online.reader_text') }}

            </div>
            <div class="common">
              {{ $t('ss_online.Who_for') }}
            </div>
            <div class="introduce">
              <van-tag size="medium" class="pl-10 pr-10 mr-10" round type="primary" color="#F2F3F5"
                text-color="#323233">{{
                  $t('ss_online.GK_2') }}</van-tag>
              <van-tag size="medium" class="pl-10 pr-10" round type="primary" color="#F2F3F5" text-color="#323233">{{
                $t('ss_online.Yuyue') }}</van-tag>

            </div>
            <!-- <div class="common">
              {{ $t('ss_online.tutorial') }}
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.en_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lh3zFqdC0gkqhj_8laqkaJf_TFp2.mp4"
                  width="100%" controls></video>
              </div>
            </div>
            <div class="video" href="">
              <a class="pdf_url"
                href="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4">
                <img src="@/assets/icon.png" alt="Logo" class="url" />
                <span class="text">{{ $t('ss_online.zh_Tutorial') }}</span>
              </a>
              <div class="video_url">
                <video
                  poster="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4?vframe/jpg/offset/1"
                  src="https://m2.files.ivykids.cn/cloud01-file-converted-8014971lidKXjnjKv0qzumwOnKXu0nkKRjX.mp4"
                  width="100%" controls></video>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tools">
      <span class="iconfont iconfont-download"></span>
      <a href="https://m2.files.ivykids.cn/cloud01-file-8033477Fu4sMgXjcJnOSDfFkUW5yAl4wqob.pdf">{{
        $t('ss_online.download_pdf') }}</a>
    </div>
    <van-action-sheet v-model="show" :title="$t('ss_online.support_home')">
      <div class="content_home color6 font14">
        <div>{{ $t('ss_online.work_with') }}</div>
        <div class="pt-10">{{ $t('ss_online.make_a') }}</div>
        <div class="pt-10">{{ $t('ss_online.provide_support') }}</div>
        <div class="pt-10 mb-20">{{ $t('ss_online.monitor') }}</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { ActionSheet, Toast, Icon, Tag } from "vant";
import SwitchLang from "@/components/SwitchLang";

Vue.use(ActionSheet);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Tag);

export default {
  name: "JournalContent",
  components: {
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      show: false,
      arts: false,
      science: false,
      Kids: false,
      math: false,
      newsela: false,
      seesaw: false,
      level_chinese: false,
      Reader: false
    };
  },
  mounted() {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#fff')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    ixlShow(type, name) {
      console.log(type)
      this[name] = !type
    },
    copyText(text) {
      this.$copyText(text).then(() => {
        Toast(this.$t('student.copy_success'))
      })
    }
  },
};
</script>
<style lang="less" scoped>
.titleTexts {
  .title_1 {
    padding: 12px 16px;
    font-size: 13px;
    color: #333333;
    background: #fff;

    .ss_title {
      margin-top: 12px;
    }

    .log_in {
      font-weight: bold;
      margin-top: 12px;
    }

    .question {
      color: #4D88D2;
      margin-top: 12px;
      font-size: 14px;
    }
  }

  .list {
    padding: 12px 16px 30px 16px;
    font-size: 13px;
    background: #fff;
    border-top: 12px solid #F7F8FA;

    .title {
      font-size: 13px;
      color: #666666;
      padding-top: 12px;
      padding-bottom: 4px;
    }

    .itemText {
      display: flex;
      padding: 14px 16px;
      border-radius: 8px;
      border: 1px solid #DCDEE0;
      margin-top: 12px;
      align-items: center;

      .flex1 {
        flex: 1;
        font-size: 15px;
      }

      i {
        font-size: 14px;
      }
    }

    .active {
      background: #4D88D2;
      color: #Fff;
      border-radius: 8px 8px 0px 0px;
      border: 1px solid #4D88D2;

    }

    .activeText {
      border: 1px solid #DCDEE0;
      border-top: none;
      padding: 0 16px 16px;
      border-radius: 0px 0px 8px 8px;

      .common {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        padding-top: 20px;
      }

      .introduce {
        font-size: 13px;
        color: #333333;
        margin-top: 12px;
      }

      .pdf_url {
        padding: 12px;
        background: #F7F8FA;
        border-radius: 6px;
        display: flex;
        align-items: center;
        margin-top: 12px;

        .text {
          flex: 1;
          font-size: 14px;
          color: #333333;
          margin-left: 8px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }

      .video {
        background: #F7F8FA;

        .video_url {
          padding: 4px 16px 16px
        }
      }
    }
  }

  .orange {
    color: #F5A429
  }

  .content_home {
    padding: 16px
  }

  .bottom-tools {
    text-align: center;
    background-color: #fff;
    padding-bottom: 24px;
    font-size: 15px;
    font-weight: 400;
    color: #4D88D2;

    a {
      margin-left: 3px;
      color: #4D88D2 !important;
    }
  }
}
</style>
