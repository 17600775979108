<template>
  <van-action-sheet v-model="newShow" :title="$t('bill.Detail')" :lock-scroll="true">
    <div class="scrollY">
      <van-loading v-if="loadingDetail" vertical class="mt-50 mb-50"></van-loading>
      <div v-else class="pb-5">
        <div class="flex flexCenter van-row--justify-center mt-10 mb-20">
          <span class="fee">{{ billingDetails.amount_string }}</span>
        </div>
        <div>
          <van-cell class="cell-custom" center :title="$t('bill.InvoiceTitle')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.title" />
          <van-cell v-if="billingDetails.fee_type === 'lunch'" class="cell-custom" :border="false">
            <div slot="title" class="flex van-row--justify-space-between">
              <span class="cell-title">{{ $t("bill.FeeType") }}</span>
              <div class="flex van-row--justify-end">
                <span class="mr-5">{{ billingDetails.fee_type_title }}</span>
                <router-link to="/Lunch" class="meal-details-link">
                  {{ $t("bill.ViewLunchDetail") }}
                  <van-icon name="arrow" />
                </router-link>
              </div>
            </div>
          </van-cell>
          <van-cell v-else class="cell-custom" center :title="$t('bill.FeeType')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.fee_type_title" />
          <van-cell v-if="billingDetails.deposit_amount > 0" class="cell-custom" center :title="$t('bill.UseTuitionDeposit')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.deposit_amount_string" />
          <van-cell class="cell-custom" center :title="$t('bill.Status')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.invoice_status_title" />
          <!-- <van-cell class="cell-custom" center :title="$t('bill.InvoicePeroid')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.interval" />
          <van-cell class="cell-custom" center :title="$t('bill.DueDate')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.due_time" />
          <van-cell class="cell-custom" center :title="$t('bill.AddTimestamp')" value-class="cell-value flexAuto" title-class="cell-title" :border="false" :value="billingDetails.timestamp" /> -->
        </div>
        <div v-if="billingPayment.length > 0">
          <van-divider />
          <div class="payment">
            <img src="@/assets/bill2.png" alt="" class="payImg" />
            <div class="paymentTitle">{{ $t("bill.PaymentList") }}</div>
            <div class="paymentList">
              <div v-for="(item, index) in billingPayment" :key="index" class="billList">
                <div class="flex1">
                  <div>{{ item.type }}</div>
                  <div>{{ item.timestampe }}</div>
                </div>
                <div class="num">{{ item.amount_string }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="billingRefund.length > 0">
          <div class="refund">
            <img src="@/assets/bill1.png" alt="" class="payImg" />
            <div class="refundTitle">{{ $t("bill.RefundList") }}</div>
            <div class="refundList">
              <div v-for="(item, index) in billingRefund" :key="index" class="refundBill">
                <div class="flex">
                  <div class="flex1">
                    <div>{{ item.title }}</div>
                  </div>
                  <div class="num">{{ item.refund_amount_string }}</div>
                </div>
                <div class="color6 font12">
                  <span>{{ $t("bill.RefundPeroid") }}：</span><span>{{ item.refund_period }}</span>
                </div>
                <div class="color6 font12 mt-5">
                  <span>{{ $t("bill.RefundDate") }}：</span><span>{{ item.timestamp }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: "BillDetail",
  props: ["inoutSymbol", "billingDetails", "billingPayment", "billingRefund", "show", "loadingDetail"],
  data() {
    return {
      newShow: this.show,
      newLoadingDetail: this.loadingDetail,
    };
  },
  watch: {
    show(value) {
      this.newShow = value;
    },
    newShow(value) {
      this.$emit("show", value);
    },
    loadingDetail(value) {
      this.newLoadingDetail = value;
    },
    newLoadingDetail(value) {
      this.$emit("loadingDetail", value);
    },
  },
};
</script>

<style scoped lang="less">
.scrollY {
  overflow-y: auto;
  max-height: 500px;
}
.flexCenter {
  align-items: center;

  .flex1 {
    margin-left: 8px;
  }
}

.cell-value {
  color: #323233;
}

.fee {
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #323233;
  line-height: 28px;
}

.cell-title {
  flex: unset;
  width: 25%;
  color: #646566;
}

.flexAuto {
  flex: auto;
}

.cell-custom {
  padding-top: 0;
}

.record-title {
  font-weight: 600;
  color: #323233;
  font-size: 16px;
}

.meal-details-link {
  color: #4d88d2;
}
.payment {
  background: linear-gradient(180deg, #d3eecd 0%, #ecf7df 19%, #ffffff 100%);
  border-radius: 8px;
  border: 1px solid #d5efcf;
  margin: 24px 16px;
  position: relative;
  .paymentTitle {
    font-weight: 500;
    font-size: 16px;
    color: #139f2a;
    line-height: 22px;
    padding: 16px;
  }
  .paymentList {
    background: linear-gradient(180deg, #fafff7 0%, #ffffff 100%);
    border-radius: 12px 12px 8px 8px;
    padding: 0 16px;
    .billList:last-child {
      border-bottom: none;
    }
    .billList {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px dashed #d5efcf;
      .flex1 {
        flex: 1;
        color: #666;
        font-size: 12px;
        div {
          line-height: 20px;
        }
      }
      .num {
        font-weight: 500;
        font-size: 18px;
        color: #323233;
        line-height: 24px;
      }
    }
  }
}
.refund {
  background: linear-gradient(180deg, #ffd4c0 0%, #ffefef 40%, #ffffff 100%);
  border-radius: 8px;
  margin: 24px 16px;
  border: 1px solid #ffd8d8;
  position: relative;
  .refundTitle {
    font-weight: 500;
    font-size: 16px;
    color: #dc4439;
    line-height: 22px;
    padding: 16px;
  }
  .refundList {
    background: linear-gradient(180deg, #fff7f5 0%, #ffffff 100%);
    border-radius: 12px 12px 8px 8px;
    padding: 0 16px;
    .refundBill:last-child {
      border-bottom: none;
    }
    .refundBill {
      padding: 18px 0;
      border-bottom: 1px dashed #ffd8d8;
      .flex {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .flex1 {
          flex: 1;
          color: #323233;
          font-size: 14px;
        }
        .num {
          font-weight: 500;
          font-size: 18px;
          color: #323233;
          line-height: 24px;
        }
      }
    }
  }
}
.payImg {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
}
</style>
