<template>
  <div class="background-white">
    <children @change="childChange"/>
    <van-tabs bind:click="onClick" :sticky="true" color="#4D88D2" v-if="!loading">
      <van-tab :title="$t('bill.AccountCredits')">
        <van-row type="flex" justify="center">
          <van-col span="22">
            <div class="group_balance">
              <div class="text-wrapper">
                <span class="text-group">{{$t('bill.AccountCredits')}}</span>
              </div>
              <div class="group_5">
                <div class="balance">
                  <span class="balance_num">{{balance}}</span>
                  <span class="balance_yuan">{{$t('bill.Yuan')}}</span>
                </div>
              </div>
            </div>
          </van-col>
        </van-row>
        <van-cell
            v-for="(item,index) in creditList"
            value-class="cell-value"
            title-class="cell-title"
            label-class="cell-label"
            center
            :is-link="item.invoice_id!=0 || item.itemname=='lunch'"
            :key="index"
            :title="item.itemname_title"
            :value="inout_symbol[item.inout]+item.amount_string"
            :label="item.updated_time"
            @click="changeBill(item.invoice_id,item.inout,item.itemname)" />
      </van-tab>
      <van-tab :title="$t('bill.DepositBalance')">
        <van-row type="flex" justify="center">
          <van-col span="22">
            <div class="group_deposit">
              <div class="text-wrapper">
                <span class="text-group">{{$t('bill.DepositBalance')}}</span>
              </div>
              <div class="group_5">
                <div class="balance">
                  <span class="balance_num">{{depositBalance}}</span>
                  <span class="balance_yuan">{{$t('bill.Yuan')}}</span>
                </div>
              </div>
            </div>
          </van-col>
        </van-row>
        <van-cell
            v-for="(item,index) in depositList"
            value-class="cell-value"
            title-class="cell-title"
            label-class="cell-label"
            center
            :is-link="item.invoice_id!=0"
            :key="index"
            :title="$t('bill.'+deposit_inout_symbol[item.inout])+item.title+$t('bill.Tuition')"
            :value="inout_symbol[item.inout]+item.amount_string"
            :label="item.timestamp"
            @click="changeBill(item.invoice_id,item.inout)"/>
      </van-tab>
    </van-tabs>
    <div class="van-list__finished-text" v-if="!loading">{{$t('bill.Nomoredata')}}</div>
    <van-loading v-if="loading" :vertical="true"/>
    <billDetail :inoutSymbol="inoutSymbol" :billingDetails="billingDetails" :billingPayment="billingPayment" :show="show"
                :loadingDetail="loadingDetail" :billingRefund="billingRefund" @loadingDetail="setLoadingDetail" @show="setShow"/>
  </div>
</template>

<script>
import Vue from "vue";
import Children from '@/components/Children.vue'
import BillDetail from '@/components/BillDetail.vue'
import {ActionSheet, Cell, Divider, List, Loading, Row,Sticky,Col,Dialog} from "vant";
import {getBillingDetails, getMyAccountBalance} from "@/api/bill";

Vue.use(ActionSheet, Row, Cell, List, Divider, Loading,Sticky,Col,Dialog);

export default {
  name: "AccountBalance",
  components: {
    'children': Children,
    'billDetail': BillDetail,
  },
  data() {
    return {
      childrenList: [],
      childid: '',
      childrenStatus: {},
      show: false,
      creditList:'',
      depositList:'',
      balance:'',
      depositBalance:'',
      inout_symbol:{
        'in':'+',
        'out':'-'
      },
      deposit_inout_symbol:{
        'in':'Deposit',
        'out':'Deduct'
      },
      inoutSymbol: '',//+ -
      billingDetails: '',//账单详情
      billingPayment: '',//付款记录
      billingRefund: '',//退款记录
      loading:true,
      loadingDetail:true,
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  created() {

  },
  methods: {
    setShow(val){
      this.show = val
    },
    setLoadingDetail(val){
      this.loadingDetail = val
    },
    childChange(val) {
      this.childid = val.id
      this.getMyAccountBalance()
    },
    getMyAccountBalance(){
      this.loading = true
      let params = {
        childId:this.childid
      }
      getMyAccountBalance(params).then((res)=>{
        this.loading = false
        this.creditList = res.data.credit
        this.depositList = res.data.depositDetail
        this.balance = res.data.balance
        this.depositBalance = res.data.deposit
      })
    },
    changeBill(invoice_id, inout = 'out',type=''){
      if(invoice_id){
        let params = {
          invoice_id: invoice_id,
        }
        this.inoutSymbol = inout === 'in' ? '+' : '-'
        this.show = true
        this.loadingDetail = true
        getBillingDetails(params).then((res) => {
          this.billingDetails = res.data.detail
          this.billingPayment = res.data.payment
          this.loadingDetail=false
        })
      }else{
        if(type === 'lunch'){
          Dialog.confirm({
            title: '',
            message: this.$t('bill.ViewLunchDetail')+''
          }).then(() => {
            // on confirm
            this.$router.push({ path: '/Lunch' })
          }).catch(() => {
            // on cancel
          });
        }
      }
    }
  }
}
</script>

<style scoped  lang="less">
.background-white {
  background: #fff;
}
.top_div{
  height: 1px;
  width:100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #ffffff
}
.group_balance {
  background: url('../assets/account_balance@2x.png') 100% no-repeat;
  background-size: 100% 100%;
  align-self: center;
  margin-top: 16px;
  //width: 343px;
  padding: 16px 101px 24px 20px;
}
.group_deposit {
  background: url('../assets/deposit@2x.png') 100% no-repeat;
  background-size: 100% 100%;
  align-self: center;
  margin-top: 16px;
  //width: 343px;
  padding: 16px 101px 24px 20px;
}

.text-wrapper {
  margin-right: 166px;
}
.text-group {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.group_5 {
  margin: 18px 0 0 82px;
}
.balance {
  width: 140px;
  height: 40px;
  overflow-wrap: break-word;
  font-size: 0;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin: 0 auto;
}
.balance_num {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
}
.balance_yuan {
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: left;
  line-height: 40px;
}
.cell-value {
  color: #323233;
  font-weight: 500;
  font-size: 15px;
}
.cell-title{
  color: #323233;
  font-weight: 400;
  font-size: 14px;
}
.cell-label{
  color: #969799;
  font-size: 12px;
  font-weight: 400;
}
</style>