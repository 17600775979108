<template>
    <div>
        <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" />
        <van-row type="flex" align="center">
            <van-col span="16">
            </van-col>
            <van-col span="8">
                <div class="lang"><switch-lang color="#377ED7" /></div>
            </van-col>
        </van-row>
        <van-tabs v-model="active" color="#4D88D2" background="#111265" title-active-color="#fff" @click="onClick"
            title-inactive-color="#969799">
            <van-tab :title="$t('schoolTeams.Information')" name="info">
                <div v-if="infoData.home" class="homeInfo">
                    <img src="../assets/phoenix.png" alt="" class="phoenixImg" />
                    <div v-html="updatedContent" class="teamcontentData" @click="textClick(updatedContent,$event)"></div>
                    <div class="important">
                        <div class="title_1 mt-10">
                            <div class="title_text">{{$t('schoolTeams.Information')}}</div>
                            <span class="bg">
                                <span class="yellow"></span>
                                <span class="blue"></span>
                            </span>
                        </div>
                        <div class="importantList">
                            <div class="listInfo flex" v-for="(list, index) in infoData.list" :key="index"
                                @click="showInfo(list)">
                                <div class="color3 font14 flex1">{{ list.title }}</div>
                                <div><van-icon name="down" class="transform" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="contact">
                        <div class="title_1 mt-15">
                            <div class="title_text">{{ $t('schoolTeams.Contact_Us') }}</div>
                            <span class="bg">
                                <span class="yellow"></span>
                                <span class="blue"></span>
                            </span>
                        </div>
                        <div class="flex">
                            <img :src="infoData.deptData.staff_photoUrl" alt="" />
                            <div class="staff_name">
                                <div class="name">{{ infoData.deptData.staff_name }}</div>
                                <div class="name">{{ infoData.deptData.staff_hrPosition }}</div>
                                <div class="button">
                                    <van-button icon="chat" round type="info" size="small">{{ $t("contact.message")
                                        }}</van-button>
                                </div>
                            </div>
                        </div>
                        <div class="us">
                            <div class="mb-8" @click="showTel(infoData.deptData.phone_number, 'tel')">
                                <van-icon name="phone-o" /><span class="ml-12">{{ infoData.deptData.phone_number
                                    }}</span>
                            </div>
                            <div class="mb-8" @click="showTel(infoData.deptData.email, 'email')">
                                <van-icon name="envelop-o" /><span class="ml-12">{{ infoData.deptData.email }}</span>
                            </div>
                            <div class="mb-8">
                                <van-icon name="info-o" /><span class="ml-12">{{ infoData.schoolInfo.url }}</span>
                            </div>
                            <div>
                                <van-icon name="location-o" /><span class="ml-12">{{ infoData.schoolInfo.address
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="$t('schoolTeams.All_Teams')" name="list">
                <div v-if="dataList.all_team_list">
                    <van-sticky>
                        <div class="filterTagList filterEvent">
                            <span @click="filterEvent('all')" :class="filterType == 'all' ? 'filterActive' : ''">{{
                                $t("global.All") }}</span>
                            <span v-for="(list, index) in category_config" :key="index" @click="filterEvent(list.id)"
                                :class="filterType == list.id ? 'filterActive' : ''">{{ list.title }}</span>
                        </div>
                        <div class="filterTagList groupList" v-if="group_config.length > 1">
                            <span @click="groupfilterEvent('all')"
                                :class="groupfilterType == 'all' ? 'filterActive' : ''">{{ $t("global.All") }}</span>
                            <span v-for="(list, index) in group_config" :key="index" @click="groupfilterEvent(list.key)"
                                :class="groupfilterType == list.key ? 'filterActive' : ''">{{ list.value }}</span>
                        </div>
                    </van-sticky>
                    <div class="schoolList">
                        <div class="myList" v-if="my_team_list.length"><label></label> <span>我加入的校队</span></div>
                        <div class="list" v-for="(list, index) in my_team_list" :key="index"
                            @click="routerLink(list.id)">
                            <img :src="list.poster_url" alt="" class="poster_url" />
                            <div class="title">{{ list.title }}</div>
                            <div class="flex">
                                <div class="flex1 label">
                                    <span class="bgBlue">{{ getVal(list.category_id) }}</span>
                                    <span>{{ list.type }}</span>
                                    <span>{{ list.grade_group_title }}</span>
                                </div>
                                <span class="member"><van-icon name="friends" />{{ list.member_num }}</span>
                            </div>
                        </div>
                        <div class="myList mt-32" v-if="all_team_list.length && my_team_list.length">
                            <label></label><span>其他校队</span>
                        </div>
                        <div class="list" v-for="list in all_team_list" :key="list.id" @click="routerLink(list.id)">
                            <img :src="list.poster_url" alt="" class="poster_url" />
                            <div class="title">{{ list.title }}</div>
                            <div class="flex">
                                <div class="flex1 label">
                                    <span class="bgBlue">{{ getVal(list.category_id) }}</span>
                                    <span>{{ list.type }}</span>
                                    <span>{{ list.grade_group_title }}</span>
                                </div>
                                <span class="member"><van-icon name="friends" />{{ list.member_num }}</span>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <van-empty :description="$t('calendar.no_data')"
                        image="https://m2.files.ivykids.cn/cloud01-file-8025768FswaeB4mZDKfyj6qfUmRqM8Ingtp.png"
                        v-if="all_team_list.length == 0 && my_team_list.length == 0" />
                </div>
            </van-tab>
        </van-tabs>
        <van-action-sheet v-model="show" :actions="actions" :cancel-text="$t('global.cancel')" :description="tel"
            close-on-click-action @select="onSelect" />
        <van-action-sheet v-model="infoShow">
            <div class="infoDtail">
                <div class="cross">
                    <div class="font16 color3 flex1 fontBold">{{ infoShowData.title }}</div>
                    <van-icon name="cross" class="mt-4" @click="infoShow = false" />
                </div>

                <div class="sheetcontent">
                    <div class="font14 color3" v-html="infoShowData.content"  @click="textClick(infoShowData.content,$event)"></div>
                    <div>
                        <a class="pdf_url" :href="list.file_key" v-for="(list, index) in infoShowData.file_list"
                            :key="index">
                            <img src="@/assets/PDF.png" alt="Logo" class="url" />
                            <span class="textUrl">{{ list.title }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import Vue from "vue";
import { NoticeBar, Divider, Toast, Dialog, Tab, Tabs, ImagePreview } from "vant";
import { TeamsListPreview, TeamsHomePreview } from "@/api/schoolTeams";
import storage from "store";
import { CURRENT_SCHOOLTEAMS } from "@/store/mutation-types";
import SwitchLang from "@/components/SwitchLang";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NoticeBar);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Dialog);
export default {
    name: "TeamContent",
    components: {
        "switch-lang": SwitchLang,
    },
    data() {
        return {
            active: "info",
            filterType: "all",
            group_config: [],
            category_config: [],
            childrenList: [],
            childrenStatus: {},
            dataList: {},
            groupfilterType: "all",
            infoData: {},
            show: false,
            actions: [{ name: this.$t("contact.call") }, { name: this.$t("contact.copy_phone") }],
            tel: "",
            infoShow: false,
            infoShowData: {},
            updatedContent: "",
        };
    },
    beforeCreate() {
        document.querySelector("body").setAttribute("style", "background-color:#111265;");
    },
    beforeDestroy() {
        // document.querySelector('body').removeAttribute('style')
    },
    created() {
        this.active = storage.get(CURRENT_SCHOOLTEAMS) || "info";
        if (this.active == "list") {
            this.getList();
        } else {
            this.getInfo();
        }
    },
    watch: {},
    mounted() { },
    methods: {
        textClick(content,e) {
            if (e.target.tagName.toUpperCase() === "IMG") {
                let html = content.replace(/&amp;/g, "&");
                const imgTags = html.match(/<img[^>]+>/g);
                var ret = [];
                if (imgTags) {
                    imgTags.map((url) => {
                        ret.push(url.match(/\ssrc=['"](.*?)['"]/)[1]);
                    });
                }
                let index = ret.indexOf(e.target.src);
                ImagePreview({
                    images: ret,
                    closeable: true,
                    startPosition: index,
                });
            }
        },
        onClick() {
            storage.set(CURRENT_SCHOOLTEAMS, this.active);
            if (this.active == "list") {
                this.getList();
            } else {
                this.getInfo();
            }
        },
        getList() {
            TeamsListPreview({ schoolId: this.$route.params.id }).then((res) => {
                if (res.code == 0) {
                    this.all_team_list = res.data.all_team_list;
                    this.my_team_list = res.data.my_team_list;
                    this.dataList = res.data;
                    this.group_config = res.data.group_config;
                    this.category_config = res.data.category_config;
                }
            });
        },
        getInfo() {
            TeamsHomePreview({ schoolId: this.$route.params.id }).then((res) => {
                if (res.code == 0) {
                    console.log(res)
                    const tempDiv = document.createElement("div");
                    tempDiv.innerHTML = res.data.home.content;
                    const h1Tags = tempDiv.querySelectorAll("h1");
                    h1Tags.forEach((h1) => {
                        const textContent = h1.textContent.trim();
                        const newHtml = `
                            <div class="teamtitle_1 mt-24">
                                <div class="teamtitle_text">${textContent}</div>
                                <span class="bg">
                                    <span class="yellow"></span>
                                    <span class="blue"></span>
                                </span>
                            </div>
                        `;
                        h1.outerHTML = newHtml;
                    });
                    this.updatedContent = tempDiv.innerHTML;
                    this.infoData = res.data;
                }
            });
        },
        applyFilters(teamList) {
            return teamList.filter((i) => (this.filterType === "all" || i.category_id == this.filterType) && (this.groupfilterType === "all" || i.grade_group == this.groupfilterType));
        },
        filterEvent(id) {
            this.filterType = id;
            this.updateLists();
        },
        groupfilterEvent(id) {
            this.groupfilterType = id;
            this.updateLists();
        },
        updateLists() {
            this.all_team_list = this.applyFilters(this.dataList.all_team_list);
            this.my_team_list = this.applyFilters(this.dataList.my_team_list);
        },
        getVal(id) {
            let item = this.dataList.category_config.filter((i) => i.id == id);
            return item[0].title;
        },
        showInfo(list) {
            this.infoShow = true;
            this.infoShowData = list;
        },
        routerLink(id) {
            this.$router.push({
                path: "/preview/schoolTeams/" + id,
            });
        },
        showTel(numTel, type) {
            this.showType = type;
            if (type == "email") {
                this.tel = numTel;
                this.actions = [{ name: this.$t("contact.copy_email"), value: 1 }];
            } else {
                this.numTel = numTel;
                let phone_number = numTel.split("-");
                let number1 = "";
                let number2 = "";
                let number3 = "";
                let ext = "";
                if (phone_number[0] && phone_number[0] != "") {
                    number1 = phone_number[0] + "-";
                }
                number2 = phone_number[1];
                if (phone_number[2] && phone_number[2] != "") {
                    number3 = "-" + phone_number[2];
                    ext = phone_number[2];
                }
                this.tel = number1 + number2 + number3;
                if (ext != "") {
                    this.actions = [
                        { name: this.$t("contact.call"), subname: this.$t("contact.ext_number", { x: ext }), value: 1 },
                        { name: this.$t("contact.copy_phone"), value: 2 },
                    ];
                } else {
                    this.actions = [
                        { name: this.$t("contact.call"), value: 1 },
                        { name: this.$t("contact.copy_phone"), value: 2 },
                    ];
                }
            }
            this.show = true;
        },
        onSelect(item) {
            if (this.showType == "tel" && item.value == 1) {
                let number = this.numTel.split("-");
                let numberPhone = "";
                if (number[0] != "") {
                    numberPhone = number[0] + "-" + number[1];
                } else {
                    numberPhone = number[1];
                }
                window.location.href = `tel:${numberPhone}`;
                // window.location.href = `tel:${this.tel}`;
            } else {
                var input = document.createElement("input");
                //将input的值设置为需要复制的内容
                input.value = this.tel;
                //添加input标签
                document.body.appendChild(input);
                //选中input标签
                input.select();
                //执行复制
                document.execCommand("copy");
                //移除input标签
                document.body.removeChild(input);
                Toast(this.$t("student.copy_success"));
            }
        },
    },
};
</script>
<style lang="less" scoped>
.lang {
    text-align: right;
    margin-right: 16px;
}

.filterTagList::-webkit-scrollbar {
    display: none;
}

.filterTagList {
    overflow-x: auto;
    white-space: nowrap;
    padding: 16px 16px 0;
    height: 34px;
    background: #111265;

    .filterTag {
        font-size: 13px;
        font-weight: 400;
        color: #323233;
        background: #4d88d2;
        border-radius: 50px;
        margin-right: 10px;
        padding: 6px 12px;
        display: inline-block;
    }

    .primary {
        color: #fff;
        background: #4d88d2;
    }
}

.filterEvent {
    .filterActive {
        color: #fff;
        background: #4d88d2;
    }

    span {
        padding: 6px 16px;
        color: #fff;
        font-size: 13px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        margin-right: 8px;
    }
}

.groupList {
    .filterActive {
        color: #fff;
    }

    span {
        padding-right: 16px;
        color: rgba(255, 255, 255, 0.6);
        font-size: 13px;
        margin-right: 8px;
    }
}

.schoolList {
    margin: 16px;

    .mt-32 {
        margin-top: 32px !important;
    }

    .myList {
        margin: 20px 0;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;

        label {
            position: absolute;
            width: 5px;
            height: 16px;
            background: #476bf5;
            top: 1px;
        }

        span {
            margin-left: 12px;
            line-height: 16px;
            color: #fff;
            font-size: 16px;
        }
    }

    .list {
        background: #223B9B;
        border-radius: 8px;
        padding: 8px 8px 16px;
        margin-bottom: 16px;

        .poster_url {
            height: 120px;
            width: 100%;
            object-fit: cover;
        }

        .title {
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            margin-top: 10px;
            padding: 0 8px;
        }

        .flex {
            align-items: center;
            margin-top: 8px;
            padding: 0 8px;

            .label {
                flex: 1;

                span {
                    display: inline-block;
                    padding: 4px 6px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #fff;
                    background: rgba(234, 235, 255, 0.30);
                    border-radius: 2px;
                    margin-right: 6px;
                    line-height: 12px;
                }

                .bgBlue {
                    background: #476BF5;
                }
            }

            .member {
                color: #fff;
                color: 12px;

                i {
                    font-size: 16px;
                    margin-right: 4px;
                }
            }
        }
    }
}

.homeInfo {
    .phoenixImg {
        max-width: 100%;
        margin-top: 12px;
    }

    .teamcontentData {
        color: #fff;
        padding: 0 16px 16px;
    }

    .bg {
        position: absolute;
        left: 50%;
        margin-left: -8px;
        height: 10px;
        bottom: -10px;
        z-index: 9;

        .yellow {
            width: 10px;
            height: 10px;
            background: #ffc53c;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 0;
            z-index: 99;
        }

        .blue {
            width: 10px;
            height: 10px;
            background: #476bf5;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            left: 6px;
        }
    }

    .important {
        background: #fff;
        padding: 24px 16px;

        .title_1 {
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            position: relative;
            margin-bottom: 24px;
            color: #111265;
            font-size: 22px;

            .title_text {
                position: relative;
                z-index: 99;
            }
        }

        .importantList {
            .listInfo {
                padding: 12px 16px;
                background: #edf1ff;
                border-radius: 5px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                .transform {
                    transform: rotate(-90deg);
                    color: #4d88d2;
                }
            }
        }
    }

    .contact {
        padding: 24px 16px;

        .title_1 {
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            position: relative;
            margin-bottom: 24px;
            display: inline-block;
            font-size: 22px;

            .title_text {
                position: relative;
                z-index: 99;
            }
        }

        .flex {
            color: #fff;
            align-items: center;
            margin-top: 24px;

            img {
                width: 112px;
                height: 112px;
                border-radius: 50%;
                object-fit: cover;
                border: 2px solid #476bf5;
            }

            .staff_name {
                margin-left: 16px;

                .name {
                    font-size: 16px;
                    line-height: 26px;
                }

                .van-button {
                    margin-top: 20px;
                    font-size: 12px !important;
                    padding: 0 12px;
                }
            }
        }

        .us {
            margin-top: 24px;
            padding: 18px 24px;
            background: #fff;
            border-radius: 8px;
            font-size: 14px;
            color: #666;
        }
    }
}

.infoDtail {
    .fontBold {
        font-weight: bold;
    }

    .cross {
        color: #ccc;
        font-size: 16px;
        text-align: center;
        display: flex;
        padding: 12px 16px 16px;
        border-bottom: 1px solid #ddd;

        .flex1 {
            flex: 1;
            padding: 0 8px 0 20px;
        }

        .mt-4 {
            margin-top: 2px;
        }
    }

    .sheetcontent {
        padding: 16px 16px 24px;
        min-height: 400px;
        max-height: 550px;
        overflow-y: auto;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .pdf_url {
        padding: 12px;
        background: rgba(77, 136, 210, 0.08);
        border-radius: 6px;
        display: flex;
        align-items: center;
        margin-top: 12px;

        .textUrl {
            flex: 1;
            font-size: 14px;
            color: #333333;
            margin-left: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
<style lang="less">
.childrenTeam {
    .children-wrap {
        background: #111265 !important
    }
}

.teamcontentData {
    img {
        max-width: 100%;
        height: auto;
    }

    .teamtitle_1 {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        position: relative;
        margin-bottom: 28px;
        color: #fff;
        font-size: 22px;
        padding-top: 24px;

        .teamtitle_text {
            position: relative;
            z-index: 99;
        }

        .bg {
            position: absolute;
            left: 50%;
            margin-left: -8px;
            height: 10px;
            bottom: -10px;
            z-index: 9;

            .yellow {
                width: 10px;
                height: 10px;
                background: #ffc53c;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 0;
                z-index: 99;
            }

            .blue {
                width: 10px;
                height: 10px;
                background: #476bf5;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                left: 6px;
            }
        }
    }
}
</style>