<template>
  <div class="phoenix">
    <van-row type="flex" justify="end">
      <switch-lang color="#377ED7" class="mr-16" />
    </van-row>
    <img src="../assets/phoenix.png" alt="" class="phoenixImg" />
    <div class="p20">
      <div class="text">
        <div>{{ $t("phoenix.browser") }}</div>
      </div>
      <div class="link borderTop">
        <div class="urls">https://sway.cloud.microsoft/MUe7i4JDlhyTBjpY?ref=Link</div>
        <van-button type="primary" class="mt-20" block round color="#476BF5" @click="copyUrl"> {{ $t("notice.Copy_Link") }}</van-button>
      </div>
      <div class="text borderTop">
        <div>{{ $t("phoenix.working") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchLang from "@/components/SwitchLang";
import { Toast } from "vant";

export default {
  name: "JournalContent",
  components: {
    "switch-lang": SwitchLang,
  },
  data() {
    return {};
  },
  methods: {
    copyUrl() {
      var input = document.createElement("input"); // 创建input对象
      input.value = "https://sway.cloud.microsoft/MUe7i4JDlhyTBjpY?ref=Link"; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      Toast(this.$t("student.copy_success"));
    },
  },
};
</script>
<style lang="less" scoped>
.phoenix{
    background:#111265;
    height: 100vh;
}
.mr-16 {
  margin-right: 16px;
  margin-top: 8px;
}

.phoenixImg {
  width: 100%;
}

.p20 {
  padding: 0 20px;
}

.text {
  padding: 20px 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  line-height: 24px;
}

.borderTop {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.link {
  padding: 20px 0;

  .urls {
    word-break: break-word;
    color: #ffc53c;
    font-size: 14px;
    line-height: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
