import Vue from 'vue';

export function indexList(id) {
    return Vue.axios({
        url: '/api/parent/meet/index/' + id,
        method: 'get',
    })
}

export function detail(id, params) {
    return Vue.axios({
        url: '/api/parent/meet/detail/' + id,
        method: 'get',
        params: params

    })
}

export function save(data) {
    return Vue.axios({
        url: '/api/parent/meet/save',
        method: 'post',
        data: data
    })
}

export function cancel(data) {
    return Vue.axios({
        url: '/api/parent/meet/cancel',
        method: 'post',
        data: data
    })
}
