<template>
  <div>
    <div v-if="data.meet_items">
      <van-notice-bar left-icon="volume-o" class="alignPtc" :text="$t('ParentSchedule.Scheduled_days')" wrapable :scrollable="false" />
      <div class="box" v-if="childData && childData.length > 0">
        <div v-for="(item, key) in childData" :key="key">
          <div class="box-1" v-if="data.meet_items && data.meet_items[item.id]">
            <div class="expiration" v-if="data.meet_status != 1">预约截止：{{ data.meet_end }}</div>
            <div class="ele">
              <img :src="item.avatar" :alt="item.name" class="avatar" referrerpolicy="no-referrer" />
              <div class="ele-2">
                <div class="ele-2-name">{{ item.name }}</div>
                <div class="ele-2-class">{{ item.className }}</div>
              </div>
              <div>
                <div class="color9" v-if="data.meet_status == 1">{{ $t("ptc.not_opened") }}</div>
                <div v-if="data.meet_status == 2">
                  <van-button round type="info" size="small" v-if="data.meet_items[item.id].booked == 0" @click="confirmBook(item.id)">{{ $t("ptc.schedule_now") }}</van-button>
                </div>
                <div v-if="data.meet_status == 3"><van-button round type="info" disabled size="small">预约截止</van-button></div>
              </div>
            </div>
            <div v-if="data.meet_status != 1 && data.meet_items[item.id].booked == 1">
              <div class="booked">
                <img src="@/assets/ptc_time.png" alt="" />
                <span>{{ data.meet_items[item.id].target_date_format }} </span>
                <span class="ml-10">{{ data.meet_items[item.id].timeslot_format }}</span>
              </div>
              <div class="editBooked" v-if="data.meet_status != 3">
                <van-button round plain type="default" size="small" :disabled="data.meet_items[item.id].can_change == 0 ? true : false" @click="cancel(item)">{{ $t("ParentSchedule.Cancel_Schedule") }}</van-button>
                <van-button round plain type="info" class="ml-16" :disabled="data.meet_items[item.id].can_change == 0 ? true : false" size="small" @click="confirmBook(item.id)">{{ $t("ParentSchedule.Reschedule") }}</van-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loadingData">
      <van-empty :description="$t('ptc.not_opened')" />
    </div>
    <van-dialog v-model="cancelBooked" :show-cancel-button="true" :confirm-button-text="$t('global.ok')" :cancel-button-text="$t('global.cancel')" confirm-button-color="#4D88D2" :before-close="cancelConfirm">
      <div class="cancelBooked" v-if="data.meet_items && data.meet_items[cancelId]">
        <div class="title">{{ $t("ParentSchedule.Confirm_schedule") }}</div>
        <div class="color6">{{ data.meet_items[cancelId].target_date_format }}</div>
        <div class="color6 mt-4">{{ data.meet_items[cancelId].timeslot_format }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, Dialog, Tag } from "vant";
import { indexList, cancel } from "@/api/parentSchedule";
import { childList } from "@/api/child";

Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Tag);
export default {
  name: "ParentSchedule",
  data() {
    return {
      childData: {},
      data: {},
      cancelBooked: false,
      cancelId: "",
      loadingData: false,
      benDisabled: true,
    };
  },
  created() {
    childList().then((res) => {
      this.childData = res.data;
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      indexList(this.$route.params.id).then((res) => {
        this.data = res.data;
        // document.title = res.data.meet_title;
        this.loadingData = true;
      });
    },
    cancel(list) {
      this.cancelId = list.id;
      this.cancelBooked = true;
    },
    cancelConfirm(action, done) {
      if (action === "confirm") {
        cancel({ child_id: this.cancelId, plan_id: this.$route.params.id }).then((res) => {
          this.fetchData();
          Toast.success(res.msg);
          this.cancelBooked = false;
          done();
        });
      } else {
        this.cancelBooked = false;
        done();
      }
    },
    confirmBook(id) {
      this.$router.push({
        path: "/parentScheduleDate/" + this.$route.params.id + "/" + id,
      });
    },
  },
};
</script>
<style lang="less">
.alignPtc {
  align-items: normal;
  .van-icon-volume-o::before {
    padding-top: 4px;
  }
}
</style>
<style scoped lang="less">
.van-button {
  padding: 0px 16px;
}

.box {
  padding: 16px;

  .box-1 {
    padding: 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 10px;
    position: relative;

    .expiration {
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(237, 106, 12, 0.1);
      border-radius: 0px 0px 0px 4px;
      font-size: 12px;
      color: #ed6a0c;
      padding: 0px 4px;
    }

    .ele {
      display: flex;
      align-items: center;

      .avatar {
        width: 52px;
        height: 52px;
        border-radius: 8px;
      }

      .ele-2 {
        margin-left: 10px;
        flex: 1;

        .ele-2-name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          margin-bottom: 8px;
        }

        .ele-2-class {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
        }
      }
    }

    .booked {
      display: flex;
      align-items: center;
      background: rgba(69, 163, 103, 0.1);
      border-radius: 32px;
      padding: 9px 12px;
      margin-top: 16px;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        color: #45a367;
        margin-left: 8px;
        line-height: 14px;
      }
    }

    .editBooked {
      display: flex;
      margin-top: 16px;
      justify-content: flex-end;
    }
  }
}

.cancelBooked {
  padding: 24px;
  text-align: center;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #323233;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
</style>
