<template>
  <div>
    <div class="calendar" v-for="(list, index) in monlist" :key="index">
      <div class="yearMon">{{ list }}</div>
      <div class="daysWeek">
        <div class="flex week">
          <div v-for="day in daysOfWeek" :key="day" class="flex1">{{ day }}</div>
        </div>
        <div class="flex daysList" v-for="(week, indexs) in calendar[index]" :key="indexs">
          <div v-for="day in week" :key="day.date" class="flex1">
            <div v-if="filterItem[day]">
              <div v-if="filterItem[day].my_book == 1" class="mt-18">
                <div class="days green" :class="dayClick == day ? 'daysCheck' : ''" @click="selectDay(day)">{{ String(day).substring(6, 8) }}</div>
                <div class="booked">{{ $t("ParentSchedule.Scheduled") }}</div>
              </div>
              <div v-else-if="filterItem[day].all_book == 1" class="mt-18">
                <div class="days colorc" :class="dayClick == day ? 'daysCheck' : ''" @click="selectDay(day)">{{ String(day).substring(6, 8) }}</div>
                <div class="notCheck">约满</div>
              </div>
              <div v-else-if="filterItem[day].all_book == 0 && filterItem[day].can_book == 0" class="mt-18">
                <div class="days colorc" :class="dayClick == day ? 'daysCheck' : ''" @click="selectDay(day)">{{ String(day).substring(6, 8) }}</div>
                <div class="notCheck">{{ $t("ParentSchedule.Occupied") }}</div>
              </div>
              <div v-else class="mt-18">
                <div class="days dayDate" :class="dayClick == day ? 'daysCheck' : ''" @click="selectDay(day)">{{ String(day).substring(6, 8) }}</div>
              </div>
            </div>
            <div v-else class="mt-18">
              <div class="days colorc">{{ String(day).substring(6, 8) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="dayTime" title="选择时间段" @closed="closeSlot">
      <div class="timeSlot">
        <div class="title">{{ timeSlotData.target_date_format }}</div>
        <div class="timeList" v-for="(list, index) in timeSlotData.timeslots" :key="index">
          <div class="time">
            <div class="flex1">
              <div class="slot">{{ list.timeslot_cn }}</div>
              <div class="num green" v-if="list.my_book == 1">{{ $t("ptc.my_schedule_tips") }}</div>
              <div class="num" v-else>{{ $t("ParentSchedule.Remaining", { x: list.slot_num - list.booked_num }) }}</div>
            </div>
            <div v-if="list.my_book != 1">
              <van-button round type="info" class="" v-if="list.can_book == 0" size="small" disabled>{{ $t("ParentSchedule.Occupied") }}</van-button>
              <van-button round type="info" class="" v-else-if="list.all_book == 1" size="small" disabled>约满</van-button>
              <van-button round type="info" class="" v-else-if="list.can_book == 1" :disabled="detailData.can_change == 0 ? true : false" size="small" @click="slotConfirm(list)">预约</van-button>
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog v-model="cancelBooked" :show-cancel-button="true" :confirm-button-text="$t('global.ok')" :cancel-button-text="$t('global.cancel')" confirm-button-color="#4D88D2" :before-close="confirmSave">
      <div class="cancelBooked">
        <div class="title" v-if="isEdit">确认修改预约吗？</div>
        <div class="title" v-else>确认预约吗？</div>
        <div class="color6">{{ timeSlotData.target_date_format }}</div>
        <div class="color6 mt-4">{{ saveTime.timeslot_cn }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, ActionSheet, Tag } from "vant";
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(Tag);
import { detail, save } from "@/api/parentSchedule";

export default {
  data() {
    return {
      daysOfWeek: [this.$t("ParentSchedule.Sun"), this.$t("ParentSchedule.Mon"), this.$t("ParentSchedule.Tue"), this.$t("ParentSchedule.Wed"), this.$t("ParentSchedule.Thu"), this.$t("ParentSchedule.Fri"), this.$t("ParentSchedule.Sat")],
      calendar: [],
      dayTime: false,
      dayClick: "",
      monlist: [],
      filterItem: {},
      timeSlotData: {},
      cancelBooked: false,
      saveTime: {},
      isEdit: false,
    };
  },
  created() {
    this.getDetail();
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#fff");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    getDetail(type) {
      detail(this.$route.params.id, { child_id: this.$route.params.childid }).then((res) => {
        this.detailData = res.data;
        this.filterItem = {};
        res.data.items.forEach((item) => {
          this.filterItem[item.target_date] = item;
        });
        if (type) {
          this.selectDay(this.dayClick);
        }
        if (res.data.booked == 1) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }

        let startDate = new Date(this.formatDate(this.detailData.start_date + ""));
        let endDate = new Date(this.formatDate(this.detailData.end_date + ""));
        this.startDateNew = this.getLastSunday(startDate);
        this.endDateNew = this.getCurrentSunday(endDate);
        this.calendar = this.createCalendar(this.startDateNew, this.endDateNew);
        this.monlist = [
          ...new Set(
            this.calendar
              .flat(Infinity)
              .filter((day) => day !== 0)
              .map((day) => `${String(day).substring(0, 4)}年${String(day).substring(4, 6)}月`)
          ),
        ];
      });
    },
    formatDate(date) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getLastSunday(startDate) {
      const today = new Date(startDate);
      const dayOfWeek = today.getDay();
      const lastSunday = new Date(today);
      lastSunday.setDate(lastSunday.getDate() - dayOfWeek);
      return lastSunday.toISOString().split("T")[0];
    },
    getCurrentSunday(endDate) {
      const today = new Date(endDate);
      const sunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7);
      sunday.setHours(0, 0, 0, 0);
      return sunday.toISOString().split("T")[0];
    },
    createCalendar(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const calendar = [];
      let currentMonth = null;
      let currentWeek = [];
      let tempDate = new Date(startDate);

      while (tempDate <= endDate) {
        if (!currentMonth || tempDate.getMonth() !== currentMonth.getMonth()) {
          currentMonth = new Date(tempDate);
          calendar.push([]);
          // 跨月不是周日的补0
          if (tempDate.getDate() === 1 && tempDate.getDay() !== 0) {
            currentWeek = Array(tempDate.getDay()).fill(0);
          } else {
            currentWeek = [];
          }
        }
        currentWeek.push(parseInt(`${tempDate.getFullYear()}${String(tempDate.getMonth() + 1).padStart(2, "0")}${String(tempDate.getDate()).padStart(2, "0")}`));
        // 如果当前周已满或到达月末，则将当前周添加到当月，并开始新一周
        if (currentWeek.length === 7 || tempDate.getDate() === new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate()) {
          calendar[calendar.length - 1].push([...currentWeek]);
          currentWeek = [];
        }
        tempDate.setDate(tempDate.getDate() + 1);
      }
      // 确保每个月份的最后一周是完整的7天
      for (let month of calendar) {
        let lastWeek = month[month.length - 1];
        if (lastWeek.length < 7) {
          while (lastWeek.length < 7) lastWeek.push(0);
        }
      }

      return calendar;
    },
    selectDay(day) {
      this.dayClick = day;
      this.dayTime = true;
      this.timeSlotData = this.filterItem[day];
    },
    closeSlot() {
      this.dayTime = false;
      this.dayClick = "";
      this.timeSlotData = {};
    },
    slotConfirm(list) {
      this.saveTime = list;
      this.cancelBooked = true;
    },
    confirmSave(action, done) {
      if (action === "confirm") {
        save({ child_id: this.$route.params.childid, plan_id: this.$route.params.id, target_date: this.dayClick, timeslot: this.saveTime.timeslot }).then((res) => {
          if (res.code == 0) {
            this.cancelBooked = false;
            this.dayTime = false;
            this.getDetail();
            Toast.success(res.msg);
          } else {
            this.getDetail("refresh");
          }
          done();
        });
      } else {
        this.cancelBooked = false;
        done();
      }
    },
  },
};
</script>
<style scoped lang="less">
.van-button {
  padding: 0px 16px;
}

.calendar {
  text-align: center;

  .yearMon {
    font-weight: 500;
    font-size: 16px;
    color: #323233;
    line-height: 20px;
    padding: 12px;
    background: #f7f8fa;
  }

  .daysWeek {
    background: #fff;
    padding-bottom: 12px;

    .week {
      padding: 12px 0;
      font-size: 12px;
      color: #323233;
    }

    .daysList {
      height: 64px;

      .days {
        margin-top: 18px;
        width: 28px;
        height: 28px;
        text-align: center;
        margin: 0 auto;
        line-height: 28px;
        font-size: 16px;
        border: 1px solid #fff;
      }

      .mt-18 {
        margin-top: 18px;
      }

      .daysCheck {
        border-radius: 50%;
        background: #4d88d2;
        color: #fff !important;
        border: 1px solid #4d88d2;
      }

      .dayDate {
        border-radius: 50%;
        color: #4d88d2;
        border: 1px solid #4d88d2;
      }

      .notCheck {
        font-size: 12px;
        color: #c8c9cc;
        line-height: 17px;
      }

      .booked {
        font-size: 12px;
        color: #45a367;
        line-height: 17px;
      }
    }
  }
}

.colorc {
  color: #c8c9cc;
}

.green {
  color: #45a367 !important;
}

.timeSlot {
  padding: 16px;
  min-height: 300px;

  .title {
    font-weight: 500;
    font-size: 14px;
    color: #323233;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .timeList {
    margin-bottom: 12px;

    .time {
      padding: 16px;
      background: #f7f8fa;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .flex1 {
        flex: 1;

        .slot {
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 24px;
        }

        .num {
          font-size: 12px;
          color: #999999;
          line-height: 16px;
        }
      }
    }
  }
}

.cancelBooked {
  padding: 24px;
  text-align: center;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #323233;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
</style>
