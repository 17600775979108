<template>
  <van-row v-if="surveyDetail.survey_title">
    <div class="titleBg">
      <div class="title">{{ surveyDetail.survey_title }}</div>
      <div class="time">
        <van-icon name="clock-o" /><span class="ml-4">{{ surveyDetail.start_time }} ~ {{ surveyDetail.end_time }}</span>
      </div>
      <!-- <div class="desc">{{ surveyDetail.survey_intro }}</div> -->
      <div class="surveyBtn">
        <van-button round type="info"
          v-if="(question_type == 'common' && surveyDetail.common_desc && surveyDetail.common_desc != '') || (question_type == 'teacher' && surveyDetail.teacher_desc && surveyDetail.teacher_desc != '')"
          @click="showDetail('btn')">{{ $t("survey.Survey_Description") }} <van-icon name="arrow" /></van-button>
      </div>
      <div class="descBg"></div>
    </div>
    <div class="quesList">
      <div>
        <div ref="container">
          <van-sticky @change="scroll">
            <van-row type="flex" justify="space-around" class="stepLine"
              v-if="!scrollType && surveyDetail.survey_type == 2">
              <van-col span="12">
                <div class="step1 flex1">
                  <div class="stepCompleted" v-if="surveyDetail.survey_status == 1">
                    <van-icon name="passed" />
                  </div>
                  <div class="stepNum" v-else :class="question_type == 'teacher' ? 'currentStep' : ''">1</div>
                  <div>
                    <span
                      :class="surveyDetail.survey_status == 1 ? 'colorSuccess' : question_type == 'common' ? 'colorBlue' : ''">{{
                        $t("survey.Regular_Survey") }}</span>
                  </div>
                  <span class="line"></span>
                </div>
              </van-col>
              <van-col span="12">
                <div class="step2 flex1">
                  <div class="stepCompleted" v-if="surveyDetail.survey_status_teacher == 1">
                    <van-icon name="passed" />
                  </div>
                  <div class="stepNum" v-else :class="question_type == 'common' ? 'currentStep' : ''">2</div>

                  <div>
                    <span
                      :class="surveyDetail.survey_status_teacher == 1 ? 'colorSuccess' : question_type == 'teacher' ? 'colorBlue' : ''">{{
                        $t("survey.Teacher_Evaluation") }}</span>
                  </div>
                </div>
              </van-col>
            </van-row>
            <div v-else-if="scrollType" style="background: #fff; padding: 16px">
              <div class="stepProgress">
                <div class="mb-10 font14 color3 flex">
                  <span class="flex1 font500" v-if='surveyDetail.survey_type == 2'>
                    <span v-if="question_type == 'common'">{{ $t("survey.Step") }} 1/2 ：{{ $t("survey.Regular_Survey")
                      }}</span>
                    <span v-if="question_type == 'teacher'">{{ $t("survey.Step") }} 2/2 ：{{
                      $t("survey.Teacher_Evaluation") }}</span>
                  </span>
                  <span v-else class="flex1 font500">
                    {{ $t("survey.Regular_Survey") }}
                  </span>
                  <span><span class="colorBlue">{{ resultNum.length }}</span><span>/{{ question_type == "common" ?
                    questionLength() : teacherList.length }}</span></span>
                </div>
                <van-progress :show-pivot="false" pivot-color="#3187EC"
                  color="linear-gradient( 270deg, #84BDEB 0%, #3187EC 100%)" :percentage="countNum()"
                  stroke-width="6" />
              </div>
            </div>
          </van-sticky>
        </div>
      </div>
      <div v-if="question_type == 'common'" class="mt-20">
        <div class="subList" v-for="(item, index) in questionList" :key="index" :id="item.id">
          <div class="font16 color3 flex">
            <span>{{ index + 1 }}.</span>
            <div class="ml-5 flex1">
              <div class="mb-4 subTitle">
                <van-tag round type="primary" color="#EBF5ED" size="medium" text-color="#45A367"
                  v-if="!item.required">{{ $t("survey.Optional") }}</van-tag>
                <van-tag round type="primary" color="#EBF5ED" size="medium" text-color="#45A367" 
                v-if="optionGroupList[item.option_group] && optionGroupList[item.option_group].max">
                  {{ $t('survey.select_maximum', { n: optionGroupList[item.option_group].max }) }}
                </van-tag>
                <span> {{ item.title }} </span>
              </div>
              <div v-if="optionGroupList[item.option_group]">
                <div v-if="optionGroupList[item.option_group].option_cat == 1">
                  <van-radio-group v-model="item.options" v-if="item.required">
                    <van-radio :name="optionList[option].value" class="mt-15" icon-size="20px"
                      v-for="(option, idx) in optionGroupList[item.option_group].options" :key="idx"><span
                        class="font14 color3">{{ optionList[option].title }}</span></van-radio>
                  </van-radio-group>
                  <van-radio-group v-model="item.options" :max="1" v-else @change="onRadioChange($event, index)">
                    <van-radio :name="optionList[option].value" class="mt-15" icon-size="20px"
                      @click="clickReportRidio($event, index)"
                      v-for="(option, idx) in optionGroupList[item.option_group].options" :key="idx"><span
                        class="font14 color3">{{ optionList[option].title }}</span></van-radio>
                  </van-radio-group>
                </div>
                <van-checkbox-group v-model="item.options" v-if="optionGroupList[item.option_group].option_cat == 2" :max="optionGroupList[item.option_group].max">
                  <van-checkbox :name="optionList[option].value" class="mt-15" icon-size="20px" shape="square"
                    v-for="(option, idx) in optionGroupList[item.option_group].options" :key="idx">{{
                      optionList[option].title }}</van-checkbox>
                </van-checkbox-group>
                <van-field v-model="item.memo" v-if="item.comment" class="inputStyle" :placeholder="$t('survey.comments')" />
              </div>
              <div v-else>
                <van-field v-model="item.memo" class="inputStyle" rows="3" autosize type="textarea"
                :placeholder="$t('survey.comments')" show-word-limit />
              </div>
              <div v-if="item.requiredTitle" class="mt-15">
                <van-notice-bar wrapable :scrollable="false" :text="$t('survey.submitTip')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="question_type == 'teacher'" class="mb-50">
        <div class="color3 font16 font500 p16">{{ teacherQuestion.title }}</div>
        <div class="subList" v-for="(item, index) in teacherList" :key="index" :id="item.uid">
          <div class="font16 color3 flex">
            <span><img :src="item.photoUrl" alt="" class="avatar" /></span>
            <div class="ml-12 flex1">
              <div class="mt-5 subTitle">
                <div class="font15 color3 font500">{{ item.name }}</div>
                <div class="font12 color6">{{ item.hrPosition }}</div>
              </div>
            </div>
          </div>
          <van-radio-group v-model="item.options">
            <van-radio :name="option.key" class="mt-15" icon-size="20px" v-for="(option, idx) in teacherOptionList"
              :key="idx"><span class="font14 color3">{{ option.value }}</span></van-radio>
          </van-radio-group>
          <van-field v-model="item.memo" class="inputStyle" rows="3" autosize type="textarea"
            v-if="teacherQuestion.comment" :placeholder="$t('survey.comments2')" show-word-limit />
          <div v-if="item.requiredTitle" class="mt-15">
            <van-notice-bar wrapable :scrollable="false"
              text='Please answer this question then click "Submit". Thanks!' />
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <van-row type="flex" align="center">
          <van-col span="24">
            <img :src="childName(this.childid, 'avatar')" alt="" class="child" />
            <van-button type="info" block round class="btn" :disabled="submitBtnDis" @click="submit"
              v-if="surveyDetail.survey_type == 1">{{ $t("global.submit") }}</van-button>
            <van-button type="info" block round class="btn" :disabled="submitBtnDis" @click="submit"
              v-else-if="surveyDetail.survey_type == 2 && question_type == 'common'">{{ $t("uniform.Next_Step")
              }}</van-button>
            <van-button type="info" block round class="btn" :disabled="submitBtnDis" @click="submitTeacher"
              v-else-if="surveyDetail.survey_type == 2 && question_type == 'teacher'">{{ $t("global.submit")
              }}</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-dialog v-model="submitShow" :title="$t('survey.Regular_Survey')" show-cancel-button :before-close="beforeClose"
      class="setTitle">
      <div class="submitChild">
        <div class="font14 color3 mb-4">{{ $t("survey.apply_to") }}:</div>
        <div class="font12 color6 mb-20">{{ $t("survey.Synchronize_survey") }}</div>
        <div>
          <van-checkbox-group v-model="feedback_ids">
            <van-checkbox :name="item.feedbackId" class="align_start mb-12" v-for="(item, index) in sync" :key="index"
              :disabled="item.selectChild">
              <div class="font14 color3">
                <span class="mr-5">{{ childName(item.childId, "name") }} </span>
                <van-tag color="#ECF5FF" v-if="childid == item.childId" text-color="#4D88D2" round>{{
                  $t("survey.Current_Student") }}</van-tag>
                <span v-if="item.selectChild">
                  <span v-if="item.sync == 0" class="notGrade"><van-icon name="warning-o" class="mr-5" size="14" />{{
                    $t("survey.Survey_different") }}</span>
                  <span v-else-if="item.done == 1" class="notGrade"><van-icon name="warning-o" class="mr-5"
                      size="14" />{{ $t("survey.Already_submitted") }}</span>
                </span>
              </div>
              <div class="font12 color6">{{ childName(item.childId, "className") }}</div>
            </van-checkbox>
          </van-checkbox-group>
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model="tipShow" :title='$t("survey.Survey_Description")' :show-confirm-button="false"
      class="setTitle">
      <div>
        <div class="tipText">
          <div class="text" ref="scrollContainer">
            <div v-html="surveyDetail.common_desc" v-if="question_type == 'common'"></div>
            <div v-html="surveyDetail.teacher_desc" v-if="question_type == 'teacher'"></div>
          </div>
        </div>

        <div class="readBtn colorBlue"  @click="confirmTip">{{
          $t("ptc.confirm") }}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="submitRepeat" :title='$t("global.Warning")' :show-confirm-button="false" show-cancel-button
      :cancel-button-text="$t('global.close')" cancel-button-color="#4D88D2" class="setTitle" @cancel="toIndex">
      <div class="detail">
        <div class="font14 color3 mb-16">{{ $t("survey.Oops") }}</div>
        <div class="flex item_center font14 mb-16">
          <div class="color6 text" :class="locale == 'zh' ? 'width60' : 'width105'">{{ $t("survey.Submitted_by") }}
          </div>
          <div class="color3 flex1 item_center flex">
            <img src="../assets/default.png" alt="" class="avatar24" v-if="wechatDetail.headimgurl == ''">
            <van-image :src="wechatDetail.headimgurl" alt="" class="avatar24" round cover v-else />
            <span class="ml-8">{{ wechatDetail.nickname }}</span>
          </div>
        </div>
        <div class="flex item_center font14">
          <div class="color6 text" :class="locale == 'zh' ? 'width60' : 'width105'">{{ $t("survey.Time") }}</div>
          <div class="color3 flex1">{{ wechatDetail.date }}</div>
        </div>
      </div>
    </van-dialog>
  </van-row>
</template>
<script>
import { Row, Button, Image, Sticky, Progress, Toast, Dialog } from "vant";
import Vue from "vue";
Vue.use(Row, Button, Image, Sticky, Progress, Toast, Dialog);
Vue.use(Progress);
import { childList } from "@/api/child";
import { getQuestions, saveQuestions, teacherSaveQuestions } from "@/api/Survey";
import i18n from "../i18n";

export default {
  data() {
    return {
      locale: i18n.locale,
      tipShow: false,
      childid: "",
      feedback_id: "",
      question_type: "",
      childData: [],
      scrollType: false,
      submitShow: false,
      questionList: [],
      optionGroupList: {},
      optionList: {},
      surveyDetail: {},
      sync: [],
      feedback_ids: [],
      childGrade: "",
      childClass: "",
      teacherList: [],
      teacherOptionList: [],
      teacherQuestion: {},
      resultNum: [],
      commentResult: {},
      teacherResult: {},
      isReportRidio: [],
      submitBtnDis: false,
      submitRepeat: false,
      wechatDetail: {}
    };
  },
  watch: {
    questionList: {
      handler: function (newVal) {
        this.resultNum = [];
        for (var i = 0; i < newVal.length; i++) {
          let list = newVal[i];
          if ((list.options && list.options != "" && list.options.length != 0) || list.memo) {
            if (list.options && list.options != "" && list.options.length != 0) {
              Vue.set(newVal[i], "requiredTitle", false);
            }
            this.resultNum.push(list);
          }
        }
      },
      deep: true,
    },
    teacherList: {
      handler: function (newVal) {
        this.resultNum = [];
        for (var i = 0; i < newVal.length; i++) {
          let list = newVal[i];
          if (list.options) {
            Vue.set(newVal[i], "requiredTitle", false);
            this.resultNum.push(list);
          }
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  created() {
    childList().then((res) => {
      if (res.code == 0) {
        this.childData = res.data;
      }
    });
    if (this.$route.query.feedback_ids) {
      this.feedback_id = this.$route.query.feedback_ids;
    }
    if (this.$route.query.childid) {
      this.childid = this.$route.query.childid;
    }
    if (this.$route.query.question_type) {
      this.question_type = this.$route.query.question_type;
    }
    this.feedback_ids = [parseInt(this.feedback_id)];
    this.childGrade = this.childName(this.childid, "grade");
    this.childClass = this.childName(this.childid, "classid");
    this.getQuestionList();
  },
  methods: {
    showDetail() {
      this.tipShow = true;
      this.$nextTick(function () {
        this.$refs.scrollContainer.scrollTop = 0;
      });
    },
    confirmTip() {
      this.tipShow = false;
    },
    getQuestionList() {
      getQuestions({
        feedback_id: this.feedback_id,
        question_type: this.question_type,
      })
        .then((res) => {
          if (res.code == 0) {
            this.submitBtnDis = false;
            this.surveyDetail = res.data.surveyDetail;
            if (this.question_type == "common") {
              this.questionList = res.data.questionList;
              this.optionList = res.data.optionList;
              this.optionGroupList = res.data.optionGroupList;
              this.optionGroupList = res.data.optionGroupList;
              for (var i = 0; i < this.questionList.length; i++) {
                this.isReportRidio.push(false);
              }
              if (this.surveyDetail.common_desc && this.surveyDetail.common_desc != "") {
                this.tipShow = true;
                this.$nextTick(function () {
                  this.$refs.scrollContainer.scrollTop = 0;
                });
              }
            } else {
              this.teacherList = res.data.teacherList;
              this.teacherOptionList = res.data.optionList;
              this.teacherQuestion = res.data.question;
              if (this.surveyDetail.teacher_desc && this.surveyDetail.teacher_desc != "") {
                this.tipShow = true;
                this.$nextTick(function () {
                  this.$refs.scrollContainer.scrollTop = 0;
                });
              }
            }
            res.data.sync.forEach((item) => {
              if (this.childid == item.childId) {
                item.selectChild = true;
              } else if (item.sync == 1 && item.done == 0) {
                item.selectChild = false;
              } else {
                item.selectChild = true;
              }
            });
            this.sync = res.data.sync;

          }
          if (res.code == 2) {
            this.toIndex()
          }
        })
        .catch(() => { });
    },
    toIndex() {
      this.$router.push({
        name: "SurveyIndex",
      });
    },
    questionLength() {
      let len = [];
      for (var i = 0; i < this.questionList.length; i++) {
        let list = this.questionList[i];
        if (list.required || (!list.required && ((list.options && (list.options != "" || list.options.length)) || list.memo))) {
          len.push(list);
        }
      }
      return len.length;
    },
    countNum() {
      if (this.question_type == "common") {
        return (this.resultNum.length / this.questionLength()) * 100;
      } else {
        return (this.resultNum.length / this.teacherList.length) * 100;
      }
    },
    childName(id, type) {
      var title = "";
      this.childData
        .filter((item) => item.id == id)
        .map((_item) => {
          title = _item[type];
        });
      return title;
    },
    scroll(isFixed) {
      this.scrollType = isFixed;
    },
    onRadioChange(value, index) {
      if (this.isFirstRepaioChanged) {
        this.isReportRidio[index] = false;
      }
      this.$set(this.questionList[index], "options", value);
    },
    clickReportRidio(value, index) {
      this.isFirstRepaioChanged = true;
      if (this.isReportRidio[index]) {
        this.questionList[index].options = "";
      }
      this.isReportRidio[index] = true;
    },
    allSelectChildTrue(arr) {
      for (const obj of arr) {
        if (!obj.selectChild) {
          return false;
        }
      }
      return true;
    },
    submit() {
      this.commentResult = {};
      for (var i = 0; i < this.questionList.length; i++) {
        let list = this.questionList[i];
        if (list.required) {
          if (!list.options || list.options == "" || list.options.length == 0) {
            Vue.set(this.questionList[i], "requiredTitle", true);
            document.getElementById(list.id).scrollIntoView({ behavior: "smooth", block: "center" });
            return;
          } else {
            Vue.set(this.questionList[i], "requiredTitle", false);
          }
        }
        this.commentResult[list.id] = {
          options: !list.options ? [] : Array.isArray(list.options) ? list.options : [list.options],
          memo: list.memo ? list.memo.trim() : "",
        };
      }
      if (!this.allSelectChildTrue(this.sync)) {
        this.submitShow = true;
      } else {
        this.saveComment();
      }
    },
    submitTeacher() {
      this.teacherResult = {};
      for (var i = 0; i < this.teacherList.length; i++) {
        let list = this.teacherList[i];
        if (this.teacherQuestion.required) {
          if (!list.options || list.options == "" || list.options.length == 0) {
            Vue.set(this.teacherList[i], "requiredTitle", true);
            document.getElementById(list.uid).scrollIntoView({ behavior: "smooth", block: "center" });
            return;
          } else {
            Vue.set(this.teacherList[i], "requiredTitle", false);
          }
        }
        this.teacherResult[list.uid] = {
          option: !list.options ? "" : list.options,
          memo: list.memo ? list.memo.trim() : "",
        };
      }
      if (!this.allSelectChildTrue(this.sync)) {
        this.submitShow = true;
      } else {
        this.saveTeacher();
      }
    },
    saveComment(done) {
      this.submitBtnDis = true;
      saveQuestions({
        feedback_ids: this.feedback_ids,
        result: this.commentResult,
      }).then((res) => {
        if (res.code == 0) {
          if (this.surveyDetail.survey_type == 2 && this.surveyDetail.survey_status_teacher == 0) {
            this.submitShow = false;
            this.$router.replace({
              name: "SurveyStep",
              query: {
                feedback_ids: this.feedback_id,
                childid: this.childid,
                question_type: "teacher",
              },
            });
            this.surveyDetail = {};
            this.question_type = "teacher";
            this.resultNum = [];
            this.scrollType = false;
            this.getQuestionList();
          } else {
            // Toast(res.msg);
            this.$router.push({
              name: "SurveyCompleted",
            });
          }
          if (done) {
            done();
          }
        } else if (res.code == 3) {
          this.wechatDetail = res.data
          this.submitRepeat = true
        } else {
          this.submitBtnDis = false;
          this.submitShow = false;
          if (done) {
            done();
          }
        }
      });
    },
    saveTeacher(done) {
      this.submitBtnDis = true;
      teacherSaveQuestions({
        feedback_ids: this.feedback_ids,
        result: this.teacherResult,
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push({
            name: "SurveyCompleted",
          });
          if (done) {
            done();
          }

        } else if (res.code == 3) {
          this.wechatDetail = res.data
          this.submitRepeat = true
        } else {
          this.submitBtnDis = false;
          this.submitShow = false;
          if (done) {
            done();
          }
        }
      });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        if (this.question_type == "common") {
          this.saveComment(done);
        } else {
          this.saveTeacher(done);
        }
      } else {
        this.submitShow = false;
        done();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.font500 {
  font-weight: 500;
}

.item_center {
  align-items: center;
}

.colorBlue {
  color: #4d88d2;
}

.colorDisabled {
  color: #c8c9cc;
}

.avatar24 {
  width: 24px;
  height: 24px;
}

.titleBg {
  background: url(../assets/questionBg.png);
  padding: 32px 24px 24px;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #0a1f73;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .time {
    font-size: 14px;
    color: #0a1f73;
    margin-top: 12px;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    color: #4d5884;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 12px;
    width: 65%;
    padding-bottom: 24px;
  }

  .descBg {
    background: url(../assets/question.png);
    background-size: 140px 128px;
    position: absolute;
    width: 140px;
    height: 128px;
    right: 0;
    bottom: 0;
    z-index: 9;
  }

  .surveyBtn {
    margin: 28px 0 35px;
    min-height: 32px;

    button {
      height: 32px;
    }
  }
}

.quesList {
  margin-top: -30px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  width: 100%;

  .stepLine {
    background: #f7f8fa;
    border-radius: 12px;
    margin: 24px 16px 0;
    padding: 16px;
    text-align: center;

    .step1 {
      position: relative;

      .line {
        width: 80%;
        height: 2px;
        background: #dcdee0;
        border-radius: 100px;
        position: absolute;
        display: inline-block;
        top: 9px;
        left: 60%;
      }
    }

    .stepNum {
      width: 20px;
      height: 20px;
      background: #4d88d2;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 4px;
    }

    .colorSuccess {
      color: #45a367;
    }

    .mt-4 {
      margin-top: 4px;
    }

    .stepCompleted {
      font-size: 22px;
      color: #45a367;
      line-height: 1;
    }

    .currentStep {
      background: #c8c9cc;
    }
  }

  .stepProgress {
    background: #ecf5ff;
    padding: 16px;
    border-radius: 12px;
    // margin: 0 16px;
  }

  .subList {
    padding: 12px 16px;

    .subTitle {
      font-family: PingFangSC, PingFang SC;
      font-size: 16px;
      color: #323233;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .inputStyle {
      border: 1px solid #ebedf0;
      padding: 6px 12px;
      border-radius: 8px;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .submitBtn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100%);
    // margin-left: 16px;
    background: #fff;

    .btn {
      margin: 16px;
    }
  }

  .bottom-box {
    text-align: center;
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    background-color: #fff;

    .van-row {
      padding: 0 16px;
      position: relative;

      .van-button {
        margin: 16px 0;
      }

      .van-icon {
        font-size: 24px;
        color: #646566;
      }

      .child {
        width: 26px;
        height: 26px;
        position: absolute;
        left: 32px;
        top: 50%;
        margin-top: -13px;
        z-index: 99;
        border-radius: 50%;
      }
    }
  }
}

.submitChild {
  padding: 12px 24px;

  .align_start {
    align-items: start;
  }

  .notGrade {
    color: #ee0a24;
  }
}

.tipText {
  padding: 8px;

  .text {
    max-height: 300px;
    padding: 0 16px 16px;
    overflow-y: auto;
    font-weight: 400;
    font-size: 13px;
    color: #646566;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    // .linear {

    // }
  }

  .text::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
  }

  .text::-webkit-scrollbar {
    width: 5px;
    height: 13px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .text::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 28px;
  }

  .readCheckbox {
    position: relative;
    font-size: 14px;
    padding: 10px 16px 5px;

    .linear {
      position: absolute;
      top: -17px;
      left: 0;
      width: 98%;
      height: 20px;
      z-index: 999;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }
}

.readBtn {
  line-height: 48px;
  border-top: 1px solid #ebedf0;
  height: 48px;
  text-align: center;
}

.detail {
  padding:16px 24px 24px;

  .text {
    margin-right: 16px;
  }

  .width60 {
    width: 60px;
  }

  .width105 {
    width: 105px;
  }
}
</style>
<style lang="less">
.stepProgress {
  .van-progress__pivot {
    min-width: 20px;
  }

  .van-progress {
    background: #fff;
  }
}

.setTitle {
  .van-dialog__header {
    color: #333333;
  }
}

.readCheckbox {
  .van-checkbox__icon .van-icon {
    border: 1px solid #4d88d2
  }
}
</style>
